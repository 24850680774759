import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Avatar, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../Helpers/APIHelper";
import Header from "../../components/header";
import { formatDate } from "../../Helpers/formatDate";

const BlogPage = () => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const res = await getRequest("blogs");
      if (res?.data) {
        setLoading(false);
      }
      setBlogData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <section style={{ background: "#f7f7f8" }} className="blog-section">
        <Container>
          <div className="blog-layout">
            <div className="left-layout">
              {blogData &&
                blogData?.map((item, ind) => {
                  return (
                    <>
                      <div
                        key={ind}
                        onClick={() => navigate(`/blog/${item?.slug}`)}
                        className="blog-container"
                      >
                        <div className="img-ctn">
                          {item?.image_url ? (
                            <img
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              src={`${item?.image_url}`}
                              alt="blog-img"
                            />
                          ) : (
                            <Skeleton.Image
                              style={{ width: "200px", minHeight: "170px" }}
                            />
                          )}
                        </div>

                        <div className="content-ctn">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              size={"small"}
                              style={{
                                backgroundColor: "#fde3cf",
                                color: "#f56a00",
                                marginRight: "10px",
                              }}
                            >
                              {item?.posted_by.toUpperCase().slice(0, 2)}
                            </Avatar>
                            <p style={{ textTransform: "capitalize",fontSize: "18px",paddingTop: "5px" }}>{item?.posted_by}</p>
                          </div>
                          <label className="b-title" style={{ color: "#000" }}>{item?.title}</label>
                          <div>
                            <p
                              className="short-desc"
                              dangerouslySetInnerHTML={{ __html: item?.short_description }}
                            />
                          </div>

                          <p
                            className="chips-card "
                            style={{ fontSize: "12px" }}
                          >
                            {item?.posted_at}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}

              {/* {loading &&
                Array.from({ length: 3 }).map((_, index) => (
                  <BlogListSkeleton key={index} />
                ))} */}
            </div>

            {/* <div className="right-layout" >
              <h4>Latest Blog</h4>

              <div className="small-blog-ctn">
                <img src="https://miro.medium.com/v2/resize:fill:200:134/1*22QnF5qnl4TLN9b6TpYkHA.png"
                  alt="" />
                <p className="truncate" >Works in progress: The Long Journey to doing good Better
                </p>
                <div className="chips-card" >animesh</div>
              </div>

            </div> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default BlogPage;
