import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { Container } from "react-bootstrap";
import { Avatar, Divider, Empty } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../Helpers/APIHelper";
import NewFooter from "../../components/NewFooter";

const SingleBlog = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState('');
  const [blogData, setBlogData] = useState(null);
  const [allBlogData, SetAllBlogData] = useState([]);

  const fetchSingleBlog = async () => {
    try {
      if (slug) {
        const res = await getRequest(`blog/${slug}`);
        if (res.data) {
          setBlogData(res?.data);
          setLoading(false);
          transformUrlToEmbed(res?.data.url);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllBlog = async () => {
    try {
      const res = await getRequest("blogs");
      SetAllBlogData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const transformUrlToEmbed = (url) => {
    const videoId = url.split('v=')[1] || url.split('youtu.be/')[1];
    setEmbedUrl(`https://www.youtube.com/embed/${videoId}`);
  };

  useEffect(() => {
    fetchAllBlog();
    fetchSingleBlog();
  }, [slug]);

  useEffect(() => {
    if (slug && allBlogData?.length > 0) {
      const isBlogFound = allBlogData.find((item) => item.slug == slug);
      if (!isBlogFound) {
        navigate("/blog-not-found");
      }
    }
  }, [slug, allBlogData]);

  return (
    <>
      <Header />
      <div style={{ minHeight: "100vh" }} className="single-blog-section">
        <Container style={{ marginTop: "150px" }}>
          {!loading && (
            <div className="single-main-ctn">
              <h1 className="blog-title">{blogData?.title}</h1>
              {blogData?.posted_by && (
                <>
                  <div
                    style={{ marginTop: "50px", display: "flex", gap: "15px" }}
                  >
                    <Avatar
                      size={"large"}
                      style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                    >
                      {blogData?.posted_by?.toUpperCase().slice(0, 2)}
                    </Avatar>
                    <div>
                      <p style={{ textTransform: "capitalize", fontSize: "18px", paddingTop: "5px" }}>{blogData?.posted_by}</p>
                    </div>
                  </div>
                  <Divider />
                </>
              )}

              {blogData && !blogData?.image_url && (
                <div
                  style={{
                    background: "#f2f2f2",
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Empty />
                </div>
              )}

              {blogData && blogData?.image_url && (
                <div
                  style={{
                    background: "#f2f2f2",
                    width: "100%",
                    height: "400px",
                  }}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    width={"100%"}
                    height={"100%"}
                    src={`${blogData?.image_url}`}
                    alt={blogData?.title}
                  />
                </div>
              )}
              {embedUrl && (
                <div style={{ position: 'relative', paddingBottom: '56.25%', marginTop: 20, height: 0 }}>
                  <iframe
                    src={embedUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video player"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              )}
              <div
                className="short-desc"
                dangerouslySetInnerHTML={{ __html: blogData?.short_description }}
              />
              <div
                className="long-desc"
                dangerouslySetInnerHTML={{ __html: blogData?.description }}
              />
            </div>
          )}
          {/* {loading && <SingleBlogSkeleton />} */}
        </Container>
      </div>
      <NewFooter />
    </>
  );
};

export default SingleBlog;
