import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import CookiesPolicy from "./pages/cookiesPolicy";
import NotFound from "./pages/NotFound";
import Signup from "./pages/signup/Signup";
import BlogPage from "./pages/blog/BlogPage";
import SingleBlog from "./pages/single-blog/SingleBlog";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/terms-of-service"
          element={
            <TermsOfService
              metaTitle="Agreement for Using AtoZ Dispatch | Terms and Conditions"
              metaContent="Before using AtoZ Dispatch, please read and understand our terms and conditions. Our agreement outlines the rules and regulations for using our platform and creating your Booking App and website."
            />
          }
        />

        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              metaTitle="How We Protect Your Privacy on AtoZ Dispatch"
              metaContent="At AtoZ Dispatch, we are committed to protecting your privacy. Our privacy policy outlines how we collect, use, and protect your personal information when you use our platform to create your Booking App and website."
            />
          }
        />

        <Route
          exact
          path="/cookies-policy"
          element={
            <CookiesPolicy
              metaTitle="Understanding Cookies on AtoZ Dispatch "
              metaContent="Our website builder uses cookies to enhance your browsing experience. Learn more about our cookies policy and how we use cookies to improve our Booking App and website functionality."
            />
          }
        />

        <Route exact path="/blog" element={<BlogPage />} />

        <Route exact path="/blog/:slug" element={<SingleBlog />} />

        <Route
          exact
          path="/"
          element={
            <Index
              metaTitle="Best Limo Reservation Software and App - Try All-in-One Limo App | 30 Days Free Trial"
              metaContent="Elevate your limo business online effortlessly with AtoZ Dispatch. Fast, easy, and professional. Start now with 30 days Free Trial. Maximize revenue with All-in-One Limo Reservation Software with App. IOS & Android ready-to-use. Book demo now"
            />
          }
        />
        <Route exact path="/signup" element={<Signup />} />

        <Route
          path="*"
          element={
            <NotFound
              metaTitle="Limo & chauffeur  Software | Not Found"
              metaContent=""
            />
          }
        />
      </Routes>
    </>
  );
};
export default App;
