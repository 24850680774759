import React from "react";
import Services from "../components/services";
import Header from "../components/header";
import whatsapp from "../assets/image/whatsapp_logo_green.png";
import NewBannerCard from "../components/new-banner-card/NewBannerCard";
import SubscriptionPlan from "../components/SubscriptionPlan";
import FAQ from "../components/FAQ";
import NewContactUs from "../components/contact-us/NewContactUs";
import NewFooter from "../components/NewFooter";
import BannerOne from "../components/BannerOne";
import InfoSection from "../components/InfoSection";

const Index = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <BannerOne />
      <NewBannerCard />
      <InfoSection />
      <Services />
      <FAQ />
      <SubscriptionPlan />
      <NewContactUs />
      <NewFooter />
      <div className="whatsapp-ctn">
        <a
          aria-label="Chat on WhatsApp"
          rel="noreferrer"
          target="_blank"
          href="https://wa.me/+9779810101016?text=Hello%2C%20I%20would%20like%20to%20chat%20with%20you"
        >
          {" "}
          <img style={{ width: "55px" }} alt="sas" src={whatsapp} />
        </a>
      </div>
    </>
  );
};
export default Index;
